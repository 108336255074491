
import { LitElement, html, css } from 'lit';

class CrowdfundingTeaserFloatingBanner extends LitElement {
  static properties = {
    isOpen: { type: Boolean, reflect: true, attribute: false },
    bannerHeight: { type: Number, reflect: true, attribute: false }
  }
  static styles = css`
    .crowdfunding-teaser-floating {
      position: relative;
      width: 100%;
      right: 0;
      padding: 16px;
      box-sizing: border-box;
    }
    .trigger-button-wrap {
      cursor: pointer;
      display: flex;
      justify-content: end;
      z-index: 1100;
      pointer-events: auto;
      
      position: absolute;
      top: -14px;
      right: 16px;
      transform: translateY(0px);
      transition: .2s;
    }
    .trigger-button-wrap.close {
      transform: translateY(var(--move-distance));
      transition: .2s;
    }
    .trigger-button-wrap .trigger-button {
      display: inline-flex;
      padding: 6px 10px;
      align-items: center;
      gap: 8px;
      border-radius: 14px;
      background: #FFF;

      font-family: "Noto Sans JP";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      box-shadow: 0px 0px 16px 0px rgba(64, 68, 72, 0.40);
    }
    .trigger-button .open-text {
      color: var(--RGB-Button-Orange, #D76315);
      text-shadow: 1px 1px 0px rgba(235, 174, 29, 0.50);
    }
    .trigger-button .close-text {
      font-weight: 500;
      color: #A8ABA6;
    }
    .trigger-button .separator {
      width: 1px;
      height: 16px;
      background: #D9D9D9;
    }
    .banner-image {
      margin-top: 8px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: end;
      cursor: pointer;
    }
      
    .banner-image.close img {
      visibility: hidden;
      opacity: 0;
      transition: .2s;
      pointer-events: none;
    }
    .banner-image img {
      visibility: visible;
      opacity: 1;
      transition: .2s;
      pointer-events: auto;

      border-radius: 9px;
      width: 358px;
      box-shadow: 0px 0px 16px 0px rgba(64, 68, 72, 0.80);
    }
    @media screen and (max-width: 600px) {
      .banner-image img {
        width: 100%;
      }
    }
  `
  constructor() {
    super();
    this.isOpen = sessionStorage.getItem('crowdfunding-teaser-floating-banner') === 'false' ? false : true;
    this.attachShadow({ mode: "open" });
  }
  imageLoad() {
    this.bannerHeight = this.shadowRoot.querySelector('.banner-image').clientHeight;
  }
  changeIsOpen() {
    this.isOpen = !this.isOpen;
    sessionStorage.setItem('crowdfunding-teaser-floating-banner', this.isOpen);
  }
  gaTracking() {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'click_crowdfunding_teaser_banner',
    })
  }
  render() {
    return html`
      <div class="crowdfunding-teaser-floating">
        <div class="trigger-button-wrap ${this.isOpen ? '' : 'close'}" style="--move-distance: ${this.bannerHeight + 10}px;">
          <div class="trigger-button" @click="${this.changeIsOpen}">
            ${this.isOpen ? html`
              <div class="open-text">
                Special NEWS!!
              </div>
            ` : html`
              <div class="close-text">
                もう一度確認する
              </div>
            `}
            <div class="separator">
            </div>
            <div>
              ${this.isOpen ? html`
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M1.2 12L0 10.8L4.8 6L0 1.2L1.2 0L6 4.8L10.8 0L12 1.2L7.2 6L12 10.8L10.8 12L6 7.2L1.2 12Z" fill="#A8ABA6"/>
                </svg>
              ` : html`
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                  <path d="M6 8.755H0V6.755H6V0.755005H8V6.755H14V8.755H8V14.755H6V8.755Z" fill="#D76315"/>
                </svg>
              `}
            </div>
          </div>
        </div>
        <div class="banner-image ${this.isOpen ? '' : 'close'}">
          <a href="https://camp-fire.jp/projects/765818/preview?token=3q70f4f1&utm_campaign=cp_po_share_c_msg_projects_preview" target="_blank" rel="noopener noreferrer" @click="${this.gaTracking}">
            <img src="/images/bnr-crowdfunding-teaser-1.png" @load="${this.imageLoad}" class="banner-image">
          </a>
        </div>
      </div>
    `;
  }
}
customElements.define('crowdfunding-teaser-floating-banner', CrowdfundingTeaserFloatingBanner);